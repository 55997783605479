<template>
  <Teleport v-if="modelValue" to="#after-header">
    <div class="bg-black fixed inset-0 w-full h-full opacity-50 z-30" @click="close"></div>

    <div
      class="DialogContainer z-40 fixed w-full flex items-start justify-center"
      :class="{ 'is-large': type === 'large' }"
    >
      <div class="DialogBody bg-white p-5 rounded-xl flex flex-col items-center relative">
        <button
          type="button"
          class="ml-auto border border-black [ flex items-center justify-center ] h-8 w-8 rounded-xs"
          @click="close"
        >
          <svg-icon-close class="text-black" width="18" height="18" />
        </button>

        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
defineComponent({
  name: 'ModalDialog',
});
defineProps({
  title: {
    type: String,
    default: '',
  },
  modelValue: {
    type: null,
    default: false,
  },
  type: {
    type: String,
    default: 'default',
    validator: (value: string) => ['default', 'large'].includes(value),
  },
});

const emit = defineEmits(['update:modelValue']);

function close() {
  emit('update:modelValue', false);
}
</script>

<style lang="postcss" scoped>
.DialogContainer {
  height: 100vh;
  inset-inline: 0;
  inset-block-start: 100%;
  transform: translateY(-70%);

  @screen lg {
    inset-block-start: 80%;
    transform: translateY(-54%);
  }

  &.is-large {
    @screen lg {
      inset-block-start: 60%;
    }
  }
}
.DialogBody {
  max-width: 90%;
}

@screen lg {
  .DialogBody {
    width: max(600px, 50%);
    overflow: hidden;
  }
}
</style>
